import React, { FunctionComponent } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

import { Image } from "antd";
import { colors } from "../../theme/variables";
import { routes } from "../../routes";
import styled from "@emotion/styled";

const StyledImage = styled(Image)`
  width: 100%;
  margin: 1rem 0;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.25rem;
`;

const MenuLink = styled(Link)`
  margin: 1rem 0;
  text-decoration: none;
  color: ${colors.blue.primary};
  font-weight: bold;
  :hover {
    color: ${colors.blue.secondary};
  }

  :visited {
    font-style: italic;
  }
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
`;

const FooterItem = styled.span`
  padding: 0.5rem;
  font-size: 0.9rem;
  color: ${colors.blue.primary};
  font-weight: bold;
`;

const FooterLink = styled(Link)`
  padding: 0.5rem;
  text-decoration: none;
  color: ${colors.blue.primary};
  font-size: 0.9rem;
  font-weight: bold;
`;

export const SiderMenu: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    {
      logoImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Link to={routes.de.base}>
        <StyledImage
          src={data.logoImage.childImageSharp.fluid.src}
          preview={false}
        />
      </Link>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          margin: "5rem 0 2rem 0",
          height: "inherit",
        }}
      >
        <MenuWrapper>
          <MenuLink to={routes.de.projects}>Projekte</MenuLink>
          <MenuLink to={routes.de.profile}>Profil</MenuLink>
          <MenuLink to={routes.de.contact}>Kontakt</MenuLink>
        </MenuWrapper>
        <Footer>
          <FooterLink to={routes.de.imprint}>Impressum</FooterLink>
          <FooterLink to={routes.de.privacy}>Datenschutzerklärung</FooterLink>
          <FooterItem>
            &copy;
            {` ${
              new Date().getFullYear() === 2021
                ? "2021"
                : "2021-" + new Date().getFullYear()
            }`}
          </FooterItem>
        </Footer>
      </div>
    </div>
  );
};
