/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { Helmet } from "react-helmet";

type SeoProps = {
  description?: string;
  lang?: string;
  meta?: JSX.IntrinsicElements["meta"][];
  keywords?: string[];
  title?: string;
};

const SEO: FunctionComponent<SeoProps> = ({
  description,
  lang,
  meta,
  title,
}: SeoProps) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={defaultTitle ?? "Architekt Horst Ehlers"}
      titleTemplate={title ? `${title} | ${defaultTitle}` : `${defaultTitle}`}
      meta={[
        {
          name: "charset",
          content: "utf-8",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ].concat(meta as any)}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

export default SEO;
