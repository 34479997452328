/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { FunctionComponent, ReactNode } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { Layout } from "antd";
import { SiderMenu } from "./SiderMenu/SiderMenu";

type LayoutProps = {
  children: ReactNode;
  sider?: boolean;
};

export const BaseLayout: FunctionComponent<LayoutProps> = ({
  children,
  sider = true,
}: LayoutProps) => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(base: { eq: "Hintergrund.jpg" }) {
        publicURL
      }
    }
  `);

  return (
    <Layout
      style={{
        backgroundImage: `url(${data.backgroundImage.publicURL})`,
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      {sider && (
        <Layout.Sider
          breakpoint="md"
          collapsedWidth="0"
          style={{
            background: "none",
            height: "100%",
          }}
          width={"20rem"}
        >
          <SiderMenu />
        </Layout.Sider>
      )}
      <Layout.Content style={{ overflow: "auto", padding: "5rem 0" }}>
        {children}
      </Layout.Content>
    </Layout>
  );
};
