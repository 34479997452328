export const routes = {
  de: {
    base: "/",
    projects: "/projekte",
    profile: "/profil",
    contact: "/kontakt",
    imprint: "/impressum",
    privacy: "/datenschutz",
  },
}
